.qualification__container {
    margin: 0 auto;
}
.qualification_section {
    scroll-margin-top: 100px;
}
.qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.section__title {
    font-size: 29px;
justify-content: center;
display: flex;
margin-bottom: 1rem;
}
.section__subtitle {
justify-content: center;
display: flex;
margin-bottom: 3rem;
}
.qualification__button {
font-size: 20px;
font-weight: 500;
margin: 0;
cursor: pointer;
color: #544a53;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 3rem;
}
.qualification__button:hover {
    color: #212121;
}

.qualification-icon {
    font-size: 1.8rem;
    margin-right: 1rem;
    display: flex;
}
.qualification__sections {
display: grid;
grid-template-columns: 0.5fr;
justify-content: center;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: medium;
    font-weight: 500;
}
.qualification__subtitle {
display: inline-block;
font-size: 15px;
margin-bottom: 1rem;
}
.qualification__calendar {
    font-size: small;
}
.qualification__rounder {
    width: 13px;
    height: 13px;
    background-color: #544a53;
    border-radius: 50%;
     position: absolute;
    left: 50%; /* Вирівнює по центру сторінки */
    transform: translateX(-50%); /* Вирівнює круг точно по центру лінії */
    
}
.qualification_line {
    width: 2px;
    background-color: #544a53;
    position: absolute;
    top: 22%; /* Починає лінію з центру першої точки */
    left: 50%;
    transform: translateX(-50%);
    height: 80%;
}


@media (max-width: 1025px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    .qualification__container {
        margin-left: 1.5rem;
        margin-right: 1.5 rem;
    }
}
@media (max-width: 576px) {
    .qualification__sections {
        grid-template-columns: initial;
    }
}