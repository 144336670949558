.mywork {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 20px; /* Зменшимо відступи для мобільних */
}
.projects_section {
    scroll-margin-top: 100px;
}
.mywork_title {
    position: relative;
}

.mywork_title h1 {
    padding: 0px 20px; /* Зменшимо відступи */
    font-size: 28px; /* Зменшимо розмір шрифту */
}

.mywork_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}

.mywork_container img {
    box-sizing: border-box;
    width: 100%; /* Автоматична адаптація ширини */
    max-width: 390px;
    height: auto; /* Висота змінюється пропорційно ширині */
    transition: 0.3s;
    cursor: pointer;
    border-radius: 15px;
}

.mywork_container img:hover {
    transform: scale(1.10);
    border: 4px solid #e863e1;
    border-radius: 15px;
    transition: 0.3s;
}

/* Адаптивність для планшетів */
@media (max-width: 1024px) {
    .mywork_container {
        grid-template-columns: 1fr 1fr; /* 2 колонки замість 3 */
        gap: 30px;
    }

    .mywork_title h1 {
        font-size: 32px; /* Трохи менший шрифт */
    }
}

/* Адаптивність для мобільних пристроїв */
@media (max-width: 768px) {
    .mywork_container {
        grid-template-columns: 1fr; /* 1 колонка */
        gap: 20px;
    }

    .mywork_title h1 {
        font-size: 24px; /* Ще менший шрифт */
    }

    .mywork {
        margin: 40px 10px; /* Ще менші відступи */
    }
}

/* Адаптивність для великих екранів */
@media (min-width: 1440px) {
    .mywork {
        margin: 80px 200px; /* Збільшимо відступи */
    }

    .mywork_title h1 {
        font-size: 40px; /* Більший заголовок */
    }

    .mywork_container img {
        width: 450px; /* Збільшимо зображення */
        height: auto;
    }
}
