.skills-container {
    margin: 4rem 0;
    position: relative;
}
.skills_section {
    scroll-margin-top: 125px;
}

.skills-container h5 {
    font-size: 27px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-bottom: 3.5rem;
}

.skills-content {
    display: flex;
    align-items:flex-start;
    gap: 3px;
    justify-content: space-around;
    align-items: stretch;
}

.skills {
    flex: 0.4;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}

.skills-info {
    flex: 0.5;
}

@media (max-width: 1025px) {
    .skills {
        padding-left: 1rem;
    }
    .skills,
    .skills-content {
        grid-gap: 2rem;
    }
}

@media (max-width: 768px) {
    .skills-content {
        flex-direction: column-reverse;
        gap: 3rem;
    }
    .skills,
    .skill-info {
        width: 100%;
    }
.skills-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
}
.skills-container::after,
.skills-container::before {
width: 18rem;
height: 18rem;
}
}

@media (max-width: 600px) {
    .skills-container {
        padding: 0;
    }
}