/* Загальні стилі */
.about_section {
    background: #fff;
    color: #212121;
    padding: 50px 20px;
    font-family: 'Roboto', sans-serif;
}

.about_container {
    text-align: center;
    max-width: 900px;
    margin: auto;
}

.about_title {
    font-size: 36px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 20px;
}

.about_subtitle {
    font-size: 24px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 30px;
}

.about_text {
    font-size: 16px;
    color: #222020;
    margin-bottom: 40px;
}

/* Flexbox контейнер */
.card_section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Карточки */
.card {
    background: linear-gradient(90deg, #e372e9 0%, #d2c3d0 100%);
    border-radius: 16px;
    padding: 20px;
    width: 100%;
    max-width: 280px;
    color: #e4e4e4;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.3);
}

.card h4 {
    font-size: 20px;
    color: #9a1191;
}

.card p {
    font-size: 14px;
    color: #540450;
    text-align: center;
}

/* Анімація для книги */
.current_read .book_image img {
    width: 80%;
    height: auto;
    border-radius: 8px;
    margin: 0 auto;
    display: block;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.current_read .book_image img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
}

/* Анімація для іконок */
.tech_stack_icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 28px;
}

.tech_stack_icons svg {
    font-size: 32px;
    color: #430133;
    transition: color 0.3s ease, transform 0.3s ease;
}

.tech_stack_icons svg:hover {
    color: #9a1191;
    transform: scale(1.2);
}

/* Стилі для зображення ноутбука */
.tech_stack .laptop_image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tech_stack .laptop_image img {
    
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tech_stack .laptop_image img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
}

/* Теги персональних якостей */
.persona_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.persona_tags span {
    background-color: #430133;
    color: #ffffff;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Анімація при наведенні */
.persona_tags span:hover {
    background-color: #9a1191;
    color: #fff;
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Анімація при натисканні */
.persona_tags span:active {
    transform: scale(0.95);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Адаптивність */
@media (max-width: 1024px) {
    .card_section {
        gap: 15px;
    }

    .card {
        max-width: 300px;
    }

    .about_title {
        font-size: 30px;
    }

    .about_subtitle {
        font-size: 20px;
    }

    .about_text {
        font-size: 14px;
    }

    /* Адаптивні стилі для ноутбука */
    .tech_stack .laptop_image img {
        max-width: 130px;
    }
}

@media (max-width: 768px) {
    .card_section {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .card {
        max-width: 90%;
    }

    .about_title {
        font-size: 28px;
    }

    .about_subtitle {
        font-size: 18px;
    }

    .about_text {
        font-size: 14px;
    }

    /* Адаптивні стилі для ноутбука */
    .tech_stack .laptop_image img {
        max-width: 120px;
    }
}

@media (max-width: 480px) {
    .about_title {
        font-size: 24px;
    }

    .about_subtitle {
        font-size: 16px;
    }

    .about_text {
        font-size: 12px;
    }

    .tech_stack_icons svg {
        font-size: 28px;
    }

    /* Адаптивні стилі для ноутбука */
    .tech_stack .laptop_image img {
        max-width: 100px;
    }
}
