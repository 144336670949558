.footer {

    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Вирівнює всі елементи по центру */
    justify-content: center; /* Вертикальне центрування */
    text-align: center;
    gap: 1.5rem; /* Проміжок між блоками */
}
.footer_container {
    padding: 2rem 0 2rem;
     width: 100%;
    max-width: 1200px;
}
.footer_title,
.footer_link {
    color: #212121;
}
.footer_title {
    font-family: "Playfair Display", sans-serif;
    text-decoration:underline;
    text-align: center;
    margin-bottom: 1rem;
}
.footer_link {
    color: #212121;
    text-decoration: none; 
    scroll-behavior: smooth;
    font-size: 1rem;
}
.footer_link:hover {
color: #945e90;
}
.footer_list {
    display: flex;
    justify-content: center;
    column-gap: 2rem;
     padding: 0;
    margin: 2rem;
    list-style: none;
}
.footer_social {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
}
.footer-social_link {
    background-color: #212121;
    color: #fff;
    font-size: 20px;
    padding: 6px;
    border-radius: 5px;
    display: inline-flex; 
}

.footer-social_link:hover {
    background-color: #945e90;
}

.footer_copy {
    display: block;
    margin-top: 4.5rem;
    color: #212121;
    text-align: center;
    font-size: smaller;
}

@media (max-width: 1025px) {
    .footer-social_link {
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
    }
    .footer_list {
        font-size: medium;
        column-gap: 18px;
    }
}