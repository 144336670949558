.skills-info-card {
    min-height: 23rem;
    background:linear-gradient(90deg, #e372e9 0%, #d2c3d0 100%);
    border-radius: 0.65rem;
    border: 1.5px solid #945e90;
    backdrop-filter: blur(1rem);
}

.skills-info-card h6 {
    font-size: 20px;
    font-weight: 500;
    padding: 0.8rem 2rem;
    background:linear-gradient(90deg, #6f3072 0%, #20021c 100%);
    border-bottom: 1.5px solid #945e90;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skills-info-content {
    padding: 2rem;
}

.skill-info {
display: flex;
align-items: center;
justify-content: space-between;
}

.skill-info p {
font-size: 17px;
font-weight: 500;
}

.skill-info .percentage {
    color: #6f3072; 
}

.skill-progress-bg {
    width: 100%;
    height: 0.5rem;
    background: #945e90;
    border-radius: 0.5rem;
    margin: 1rem 0;
    overflow: hidden;
}

.skill-progress {
    width: 0%;
    height: 0.5rem;
    background: linear-gradient(90deg, #6f3072 0%, #20021c 100%);
    border-radius: 0.5rem;
    transition: all 0.5s ease-in-out;
}
@media (max-width: 1025px) {
   .skills-info-card h6 {
    font-size: 1.1rem;
   } 
   .skills-info p {
    font-size: 0.9rem;
   }
   .skills-info-content {
    padding: 1.5rem;
   }
}