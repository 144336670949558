:root {
  --white: #fff;
  --black: #000000;
  --light: #FFFEFE;
  --violet: linear-gradient(90deg, #e372e9 0%, #d2c3d0 100%);
}

.container {
  max-width: 1440px !important;
  margin: 0 auto;
  position: relative;

}

@media (max-width: 1440px) {
  .container {
    padding: 0 1.5rem;
    
  }
}
 



