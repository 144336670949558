
.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Додає рівномірний простір між елементами */
  text-align: left;
  padding: 50px; /* Додає відступи */
  gap: 2rem; /* Додає проміжок між текстом і зображенням */
}

.hero-img-container img {
  max-width: 265px;
  border-radius: 3%;
  margin-left: 150px;
}

.hero-content {
  max-width: 700px; /* Задаємо обмежену ширину для тексту */
}

.hero-content h1 {
  font-family: "Qwitcher Grypen", cursive;
  font-size: 85px;
  font-weight: 500;
  margin-left: 100px;
}

.hero-content h2 {  
  font-family: "Playfair Display", sans-serif;
  font-size: 25px;
  font-weight: 400;
  margin: 20px 0;  
}

.hero-content p {
  font-family: "Playfair Display", sans-serif;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 auto; 
}

.tech-icon {
  font-size: 45px;
  display: flex;
  gap: 2rem; /* Проміжок між іконками */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}

.icon-title {
  font-family: 'Playfair Display', sans-serif;
  font-size: 25px;
   font-weight: 400;
  margin-bottom: 0; /* Прибираємо зайві відступи знизу */
  margin-right: 20px; /* Відступ між заголовком та іконками */
  text-align: center;
  line-height: 45px; /* Щоб заголовок був на одній лінії з іконками */
}

.hero-icon {
  width: 3rem;
  height: auto;
  transition: all 0.3s ease;
}

.hero-icon:hover {
  transform: translateY(-0.5rem);
}

/* Адаптивність */
@media (max-width: 1025px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }

  .hero-content h1 {
    font-size: 70px;
  }

  .hero-content h2 {
    font-size: 25px;
  }

  .hero-content p {
    font-size: 16px;
  }

  .hero-img-container img {
    max-width: 150px;
    margin-left: 10px;
    
  }
   .tech-icon {
    margin-top: -10px;
    
  }
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }
   .hero-content h1 {
    font-size: 70px;
    margin-left: 20px;
  }
}