@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Ramaraja&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Qwitcher+Grypen:wght@400;700&display=swap');

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
box-sizing: border-box;
}

body {
  color: #000;
  background-color: #fff;
}

.about_section, .hero_section {
    scroll-margin-top: 80px;
}
